import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';

import {
  Grid,
  Box,
  TextField,
  Typography,
  Button,
  IconButton
} from '@mui/material';

import Alert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';

import VestingPlan from './VestingPlan';


const Release = ({
  web3,
  accounts,
  smartContract
}) => {

  const [accountAddress, setAccountAddress] = useState(null);
  const [vPlan, setVPlan] = useState([]);
  const [showSuccess, setShowSuccess] = useState(false);
  

  useEffect(() => {
    if (accountAddress && smartContract) {
      checkSmartContract();
    }
  }, [accountAddress]);
 
  const initialValues = {
    account: ''
  };

  const validationSchema = Yup.object().shape({
    account: Yup.string().max(255).required('Account is required')
  });

  const onSubmit = (
    {
      account
    }, { setSubmitting }
  ) => {
    account = account.trim();

    if (account !== '' && account === accountAddress) {
      setAccountAddress('');
    }
    setAccountAddress(account);

    setSubmitting(false);
  }

  const checkSmartContract = async () => {
    if (!accountAddress || accountAddress === '') {
      return false;
    }

    let vestingPlanLoc = null;
    try {
      vestingPlanLoc = await smartContract.methods.vestingPlan(accountAddress).call();
    } catch (error) {
      console.log(error);
    }
    
    if (vestingPlanLoc) {
      setVPlan(vestingPlanLoc);
    }
  };

  const released = async () => {
    checkSmartContract();
    setShowSuccess(true);
  };

  return (
    <>
      {showSuccess === true && <Alert severity="success" action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={() => {
              setShowSuccess(false);
            }}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        }
      >BSGG has been released successfully!</Alert>}

        <Grid
          container
          spacing={3}
          justifyContent="center"
        >
          <Grid item lg={6} md={8} sm={12} xs={12} justifyContent="center">
            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values
              }) => (
                <form onSubmit={handleSubmit} autoComplete="off">

                <TextField
                  error={Boolean(touched.account && errors.account)}
                  fullWidth
                  helperText={touched.account && errors.account}
                  label="Account"
                  margin="normal"
                  name="account"
                  required
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.account}
                  variant="outlined"
                />
                
                  
                  <Box my={2}>
                    <Button
                      color="primary"
                      disabled={isSubmitting}
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                    >
                      Check Vesting
                    </Button>
                  </Box>
                </form>
              )}
            </Formik>
          </Grid>
        </Grid>


      {accountAddress !== null && web3 !== null && <>
        <Box my={2}>
          <Typography
            color="textPrimary"
            variant="body2"
          >
            Account: {accountAddress}
          </Typography>
        </Box>
        <Box my={2}>
          <VestingPlan
          web3={web3}
          accounts={accounts}
          records={vPlan}
          smartContract={smartContract}
          accountAddress={accountAddress}
          released={released}
          />
        </Box>
      </>}

    </>
  );
};


export default Release;
