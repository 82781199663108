import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import moment from 'moment';
import NumberFormat from 'react-number-format';
import sleep from 'sleep-promise';

import {
  Grid,
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Card,
  CardActions,
  CardContent,
  Button
} from '@mui/material';

const useStyles = makeStyles(({
  actionButton: {
    minHeight: 36
  }
}));

const VestingPlan = ({
  web3,
  accounts,
  records,
  smartContract,
  accountAddress,
  released
}) => {
  const classes = useStyles();

  const [releasableAmounts, setReleasableAmounts] = useState([]);
  const [payouts, setPayouts] = useState([]);
  const [payoutProcessing, setPayoutProcessing] = useState(false);
  

  useEffect(() => {
    if (records.length > 0) {
      syncData();
    }
  }, [records]);

  async function syncData() {
    let releasables = [];

    if (!records || records.length === 0) {
      return false;
    }
    
    for (let i in records) {
      try {
        let releasableAmount = await smartContract.methods.releasableAmount(accountAddress, i).call();

        let amountConv = web3.utils.fromWei((releasableAmount).toString());

        releasables[i] = {
          amount: releasableAmount,
          amountConverted: (amountConv >= 100) ? parseInt(amountConv) : amountConv
        };
      } catch (error) {
        console.log(error);
      }
    
      await sleep(400);
    }
    setReleasableAmounts(releasables);

    
    let withdrawals = null;
    try {
      withdrawals = await smartContract.methods.payouts(accountAddress).call();
    } catch (error) {
      console.log(error);
    }

    if (withdrawals && withdrawals.length > 0) {
      let pOuts = [];
      
      for (let i in withdrawals) {
        pOuts.unshift(withdrawals[i]);
      }

      setPayouts(pOuts);
    }
    
  }

  async function releaseProcess(key, amount) {
    setPayoutProcessing(true);

    let gasPrice = 0;
    let gasCost = 0;

    try {
      gasPrice = await web3.eth.getGasPrice();
      gasPrice = parseInt(gasPrice);

      gasCost = await smartContract.methods.release(accountAddress, key, amount.toString()).estimateGas({from: accounts[0]});
      gasCost = parseInt(gasCost);

    } catch (error) {
      console.log('Could not get gas price');
    }

    // console.log(accountAddress);
    // console.log(accounts[0]);

    let results = null;
    try {
      results = await smartContract.methods.release(accountAddress, key, amount.toString()).send({from: accounts[0], gas: gasCost.toString(), gasPrice: gasPrice.toString()});
    } catch (error) {
      setPayoutProcessing(false);
      console.log(error);
    }

    if (results && results.blockHash) {
      released();
      syncData();
    }

    setPayoutProcessing(false);
  }


  return (
    <>
      {records.length > 0 && <>

        <Grid
          container
          spacing={3}
          justifyContent="left"
        >
      {records.map((r, i) => (
        <Grid key={i} item lg={4} md={6} sm={12} justifyContent="center">
          <Card sx={{ maxWidth: 345 }}>
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                <NumberFormat value={web3.utils.fromWei(r.totalAmount.toString())} displayType={'text'} thousandSeparator={true} /> BSGG
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Total Releasable:{' '}
                {releasableAmounts.length > 0 && releasableAmounts[i].amount >= 0 && <NumberFormat value={releasableAmounts[i].amountConverted} displayType={'text'} thousandSeparator={true} />} BSGG
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Total Released:{' '}
                <NumberFormat value={web3.utils.fromWei(r.paidAmount.toString())} displayType={'text'} thousandSeparator={true} /> BSGG
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Vesting Starts:{' '}
                {moment(r.strartTime * 1000).format('YYYY-MM-DD HH:mm')}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Vesting Ends:{' '}
                {moment(r.endTime * 1000).format('YYYY-MM-DD HH:mm')}
              </Typography>
            </CardContent>
            <CardActions className={classes.actionButton}>
              {releasableAmounts.length > 0 && releasableAmounts[i].amount > 0 && accounts[0].toLowerCase() === accountAddress.toLowerCase() && <Button
                color="primary"
                fullWidth
                size="small"
                variant="contained"
                disabled={payoutProcessing}
                onClick={() => { releaseProcess(i, releasableAmounts[i].amount); }}
              >
                Release {releasableAmounts[i].amountConverted} BSGG
              </Button>}
            </CardActions>
          </Card>

        </Grid>
      ))
      }
      </Grid>

      {payouts.length > 0 && <>
        <Box my={4}>
          <Typography
            color="textPrimary"
            variant="h5"
          >
            Payouts
          </Typography>
        </Box>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                Date
              </TableCell>
              <TableCell>
                Amount
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {payouts.map((payout) => (
              <TableRow
                hover
                key={payout.time}
              >
                <TableCell>
                  {moment(payout.time * 1000).format('YYYY-MM-DD HH:mm')}
                </TableCell>
                <TableCell>
                  <NumberFormat value={web3.utils.fromWei(payout.amount, 'ether')} displayType={'text'} thousandSeparator={true} /> BSGG
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </>}

      </>}

    </>
  );
};


export default VestingPlan;
